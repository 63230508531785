export const environment = {
    baseNodeUrlServices: "https://ws.catappult.io",
    baseUploaderNodeUrlServices: "https://uploader.catappult.io",
    basePortalUrl: "https://connect.aptoide.com",
    developersConsoleUrl: "https://developer.connect.aptoide.com",
    authorizationTokenCookieName: "APTOIDE-CONNECT-TOKEN",
    clientKey: "live_CURXTJUT7RDSZI4JPZXPNYEYXAGBJ2BF",
    domainCookie: ".connect.aptoide.com",
    environment: "live",
    name: "production",
    production: true,
    timestamp: "1577105009578",
    adminConsoleUrl: "https://admin.connect.aptoide.com",
    withdrawUnderMaintenance: false,
    uploaderUnderMaintenance: false,
    indicativeEventsPrefix: "catappult_",
    documentationHostname: "https://docs.catappult.io/docs/",
    flagsUrlPrefix: "https://developer.connect.aptoide.com/assets/images/flags/",
    chinaHostname: "catappult.cn",
};
