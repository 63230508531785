import { environment } from "@env/environment";

export function isClientSideExecution() {
    if (typeof window !== "undefined") {
        return true;
    }

    return false;
}

export function isServerSideExecution() {
    return !isClientSideExecution();
}

export function isCn() {
    if (isClientSideExecution()) {
        if (environment.production && window.location.hostname.endsWith(".cn")) {
            return true;
        }
    }

    return false;
}

export function getPortalUrl() {
    if (isCn()) {
        return environment.basePortalUrl.replace(".io", ".cn");
    }

    return environment.basePortalUrl;
}

export function getDevelopersConsoleUrl() {
    if (isCn()) {
        return environment.developersConsoleUrl.replace(".io", ".cn");
    }

    return environment.developersConsoleUrl;
}

export function getDomainCookie() {
    if (environment.production && window.location.hostname.endsWith(".cn")) {
        return environment.chinaHostname;
    }

    return environment.domainCookie;
}

export function scrollToTop() {
    setTimeout(() => {
        const element = document.getElementById("scrollToTopAnchor");

        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    }, 0);
}
