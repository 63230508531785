import { Injectable } from "@angular/core";
import { AutocompleteEntry } from "@app/shared/autocomplete/autocomplete.component";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { APIRequestsService } from "./api.requests.service";
import { environment } from "@env/environment";

export interface ImageDataForValidation {
    type: string;
    fileMimeType: string;
    height: number;
    width: number;
    size: number;
}

@Injectable()
export class UtilsService {
    appcToUsdExchangeRate: BehaviorSubject<number> = new BehaviorSubject<number>(0.0);
    appcToEurExchangeRate: BehaviorSubject<number> = new BehaviorSubject<number>(0.0);

    // STATIC ASSETS
    categoriesAutoCompleteData = new BehaviorSubject<AutocompleteEntry[]>([]);
    locales = new BehaviorSubject<any[]>([]);
    maturityLevelsAutoCompleteData = new BehaviorSubject<AutocompleteEntry[]>([]);
    imageSettings = new BehaviorSubject<any>({});
    topAnchorPosition = new BehaviorSubject<number>(0);

    constructor(private requestsService: APIRequestsService, private translate: TranslateService) {}

    init() {
        this.getAppcToUsdExchangeRate();
        this.getAppcToEurExchangeRate();
    }

    convertCurrency(from: string, to: string) {
        return this.requestsService.get(`/api/wallet/currencyRate?from=${from}&to=${to}`);
    }

    getAppcToUsdExchangeRate() {
        const url = "/api/wallet/appcRate?currency=USD&nocache=1";

        this.requestsService.get(url).subscribe({
            next: (_response: any) => {
                this.appcToUsdExchangeRate.next(_response.rate);
            },
        });
    }

    getAppcToEurExchangeRate() {
        const url = "/api/wallet/appcRate?currency=EUR&nocache=1";

        this.requestsService.get(url).subscribe({
            next: (_response: any) => {
                this.appcToEurExchangeRate.next(_response.rate);
            },
        });
    }

    isMailValid(email) {
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }
}

export function isValidPackageName(packageName: string): boolean {
    return packageName.match(/^[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)+$/) !== null;
}

export function validateEmailFormat(email: string) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}

export function humanize(str: string): string {
    var i,
        frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
}

export function clone(_object: any) {
    return JSON.parse(JSON.stringify(_object));
}

export function cloneArray(_array: any[]) {
    if (_array && _array.length > 0) {
        return clone(_array);
    }
    return [];
}

export function cloneClassObject(_object: any) {
    return Object.assign(Object.create(Object.getPrototypeOf(_object)), _object);
}

export function getFlagUrl(_countryCode: string) {
    if (!_countryCode || _countryCode === "??" || _countryCode === "ZZ") {
        _countryCode = "UNKNOWN";
    }
    return `${environment.flagsUrlPrefix}${_countryCode.toUpperCase()}.svg`;
}

export function getPreviousPeriodDates(_from: Date, _to: Date) {
    const diff = Math.abs(_to.getTime()) - Math.abs(_from.getTime());
    const previous_to = new Date(_from.getTime());
    previous_to.setDate(previous_to.getDate() - 1);
    const previous_from = new Date(previous_to.getTime() - diff);

    return {
        from: previous_from,
        to: previous_to,
    };
}

export function convertDateToRequestParameterValue(_date: Date) {
    let dd = _date.getDate().toString();
    let mm = (_date.getMonth() + 1).toString();

    if (dd.length == 1) {
        dd = "0" + dd;
    }
    if (mm.length == 1) {
        mm = "0" + mm;
    }
    return _date.getFullYear() + "-" + mm + "-" + dd;
}

export function serializeObjectToQueryParams(obj: {}): string {
    const queryParameters = [];
    Object.keys(obj).map((key: string) => {
        obj[key];
        if (obj.hasOwnProperty(key) && isNotEmpty(obj[key])) {
            queryParameters.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
        }
    });
    return queryParameters.join("&");
}

export function isNotEmpty(object: any): boolean {
    return !isEmpty(object);
}

export function isEmpty(object: any): boolean {
    return object === null || object === undefined || object === "" || object === "null" || (typeof object === "object" && Object.keys(object).length === 0);
}

export class TableHeader {
    constructor(public index: number, public identifier: string, public label: string, public sortable: boolean) {}
}

export class TableRow {
    constructor(public index: number, public identifier: string, public columns: TableColumn[]) {}
}
export class TableColumn {
    constructor(
        public index: number,
        public headerIdentifier: string,
        public type: "text" | "number" | "icon" | "link" | "date" | "simpleLink" | "subscriptionStatus" | "checkbox",
        public data: any,
        public simpleLinkLabel?: string,
        public rawStatus?: string
    ) {}
}

export class TableRowAction {
    constructor(public identifier: string, public label: string, public iconUrl: string) {}
}
