import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CacheService {
    public cache = new Map<string, any>();
    public iconCache = new BehaviorSubject<any>([]);

    constructor() {
        // REFRESH CACHED REQUESTS EVERY X SECONDS/MINUTES?
    }

    get(_key: string) {
        return this.cache.get(_key);
    }

    set(_key: string, _value: any) {
        this.cache.set(_key, _value);
    }

    verifyDependenciesGroup(key: string) {
        if (key.includes("/api/wallet")) {
            this.removeCacheEntries("/api/wallet");
        } else if (key.includes("/api/applications")) {
            this.removeCacheEntries("/api/applications");
        }
    }

    public removeCacheEntries(entry: string) {
        let tempCache = [];

        this.cache.forEach((item: any) => {
            if (item.url.includes(entry)) {
                tempCache.push(item);
            }
        });

        for (let key of tempCache) {
            this.cache.delete(key.url);
        }
    }

    reset() {}
}
