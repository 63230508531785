import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";
@Component({
    selector: "app-button",
    templateUrl: "./button.component.html",
    styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnChanges {
    @Input() label: string;
    @Input() imageSrc: string = null;
    @Input() bigTextImg: boolean = false;
    @Input() targetExternal: string = null;
    @Input() targetInternal: string = null;

    @Input() facebook = false;
    @Input() google = false;
    @Input() unity = false;
    @Input() disabled = false;
    @Input() readOnly = false;
    @Input() showButtonSpinner = false;
    @Input() big = false;
    @Input() small = false;
    @Input() noMargins = false;
    @Input() verySmall = false;
    @Input() verySmallButWide = false;
    @Input() roundedCorners = false;
    @Input() attachToInputOnLeft = false;
    @Input() boldText = false;
    @Input() thinText = false;

    @Output() onClick = new EventEmitter<boolean>();
    @Input() blackOnTransparent = false;
    @Input() blackOnTransparentBorder = false;
    @Input() whiteOnBlack = false;
    @Input() grayOnWhite = false;
    @Input() orangeOnTransparent = false;
    @Input() orangeOnWhite = false;
    @Input() redOnWhite = false;

    classes: string[] = [];

    button_spinner: AnimationOptions = { path: "/assets/lottie/button-loading.json" };

    constructor() {}

    ngOnChanges() {
        this.classes = ["app-button"];

        const prefix = "app-button--";

        if (this.whiteOnBlack) {
            this.classes.push(prefix + "whiteOnBlack");
        } else if (this.blackOnTransparent) {
            this.classes.push(prefix + "blackOnTransparent");
        } else if (this.orangeOnTransparent) {
            this.classes.push(prefix + "orangeOnTransparent");
        } else if (this.orangeOnWhite) {
            this.classes.push(prefix + "orangeOnWhite");
        } else if (this.redOnWhite) {
            this.classes.push(prefix + "redOnWhite");
        } else if (this.grayOnWhite) {
            this.classes.push(prefix + "grayOnWhite");
        } else if (this.blackOnTransparentBorder) {
            this.classes.push(prefix + "blackOnTransparentBorder");
        } else {
            this.classes.push(prefix + "whiteOnOrange");
        }

        if (this.boldText) {
            this.classes.push(prefix + "boldText");
        }

        if (this.thinText) {
            this.classes.push(prefix + "thinText");
        }

        if (this.roundedCorners) {
            this.classes.push(prefix + "roundedCorners");
        }

        if (this.attachToInputOnLeft) {
            this.classes.push(prefix + "attachToInputOnLeft");
        }

        if (this.big) {
            this.classes.push(prefix + "big");
        }

        if (this.small) {
            this.classes.push(prefix + "small");
        }

        if (this.verySmall) {
            this.classes.push(prefix + "verySmall");
        }

        if (this.verySmallButWide) {
            this.classes.push(prefix + "verySmallButWide");
        }

        if (this.unity || this.facebook || this.google) {
            this.classes.push(prefix + "social");
        }

        if (this.disabled) {
            this.classes.push(prefix + "disabled");
        }

        if (this.noMargins) {
            this.classes.push(prefix + "noMargins");
        }

        if (this.bigTextImg) {
            this.classes.push(prefix + "bigTextImg");
        }
    }

    callOnClick() {
        if (this.disabled || this.readOnly || this.showButtonSpinner) {
            return;
        }

        this.onClick.emit(true);
    }
}
