<div class="input-widget" [attr.data-tooltip]="tooltip" style="position: relative">
    <label *ngIf="label">
        <p class="veryVerySmall orange defaultLineHeight uppercase">{{ label }}:<span *ngIf="mandatory">*</span></p>
    </label>

    <input
        [ngClass]="class ? class : ''"
        [class.big]="big"
        [class.veryBig]="veryBig"
        [class.veryRounded]="veryRounded"
        [class.filterImage]="filterImage"
        [class.unit]="unit"
        [class.readOnly]="readOnly"
        [class.ellipsis]="ellipsis"
        [class.attachToButtonOnRight]="attachToButtonOnRight"
        [class.noBorderRadiusRight]="noBorderRadiusRight"
        [class.noBorderRadiusLeft]="noBorderRadiusLeft"
        [class.attachOnLeft]="attachOnLeft"
        [class.disabled]="disabled"
        [class.normal]="normal"
        [class.otpInput]="otpInput"
        [class.not-normal]="!normal"
        [class.iconURL]="iconUrl"
        [class.smallPlaceHolder]="smallPlaceHolder"
        [pattern]="pattern"
        [maxlength]="maxlength"
        [class.error]="showSpecificError || showError"
        [name]="name"
        [type]="type"
        [placeholder]="placeholder"
        [disabled]="disabled || readOnly"
        [ngModel]="value"
        (ngModelChange)="changed($event)"
        [min]="0"
        [step]="0.01"
        (keyup.enter)="onEnter($event)"
        title=""
    />
    <p class="unit darkestGray veryVerySmall italic uppercase" [class.error]="showError">
        {{ unit }}
    </p>
    <!-- <img
    src="/assets/images/clear_blue_dark.svg"
    class="clear"
    [class.normal]="normal"
    [class.big]="big"
    *ngIf="!disabled && !readOnly && showClear && value"
    (click)="clear()"
  /> -->
    <ng-content></ng-content>
</div>
<div class="error-box" *ngIf="value && showError && error">
    <p class="superTiny red thin">
        {{ error }}
    </p>
</div>
<div class="error-box" *ngIf="value && showSpecificError && specificError">
    <p class="superTiny red thin">
        {{ specificError }}
    </p>
</div>
